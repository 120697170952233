import React, { useEffect, useState } from "react";
import "../assets/styles/Wallet.css";
import { connect } from "react-redux";
import { Card, Col, Row } from "react-bootstrap";
import { PanelWallet } from "./wallet/PanelWallet";
import { FaCopy } from "react-icons/fa";
import { IoMdAddCircle } from "react-icons/io";
import { AiFillMinusCircle } from "react-icons/ai";
import { getData, postData } from "../apis";
import AlertUser from "../Alerts/userAlerts";
import { convertHash } from "./wallet/convertHash";

const Wallet = ({ wallet }) => {
  const [dataManaWallet, setdataManaWallet] = useState();
  const [showWithdraw, setshowWithdraw] = useState(false);
  const [walletAddress, setwalletAddress] = useState();
  const [amount, setamount] = useState();
  const [showDeposit, setshowDeposit] = useState(false);

  const functionWithdraw = () => {
    showWithdraw === false ? setshowWithdraw(true) : setshowWithdraw(false);
    setshowDeposit(false);
  };

  const functionDeposit = () => {
    showDeposit === false ? setshowDeposit(true) : setshowDeposit(false);
    setshowWithdraw(false);
  };

  //connect to metamask
  /*const setUserAccount = async () => {
    if (window.ethereum) {

      await window.ethereum.request({
        method: "wallet_requestPermissions", //eth_requestAccounts
        params: [
          {
            eth_accounts: {},
          },
        ],
      });

      getMetamaskAccount();
    } else {
      if (!window.ethereum) {
        installMetamask();
      }
    }
  };*/

  //install metamask if extension is not installed
  /*const installMetamask = () => {
    Swal.fire({
      text: "Please Install a Wallet",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FFF",
      confirmButtonText:
        '<a className="colorLink" href="https://metamask.io/download.html" target="_blank">Install Metamask!</a>',
    });
  };*/

  //get user account from metamask
  /*const getMetamaskAccount = async () => {
    if (window.ethereum) {
      setshowWithdraw(true)
      web3.eth.getAccounts().then((accounts) => {
        dispatch(setAddress(accounts[0]));
       // SetUserBalance(accounts[0]);
      });
    }
  };*/

  //get user balance fron metamask
  // const SetUserBalance = async (fromAddress) => {
  //   await web3.eth.getBalance(fromAddress).then((value) => {
  //     const credit = web3.utils.fromWei(value, "ether");
  //     dispatch(setBalance(credit));
  //   });
  // };

  // Disconnect from Metamask wallet and clear localstorage information.
  // const disconnectMetamask = async () => {
  //   try {
  //     dispatch(setBalance(null));
  //     dispatch(setAddress(null));
  //   } catch (error) {
  //     dispatch(setError("Error on disconnnect walllet"));
  //     console.log("Error on disconnnect: ", error);
  //   }
  // };
  const dataWallet = React.useCallback(async () => {
    const resp = await getData("/v1/wallet?coinToken=MG");
    if (resp) {
      setdataManaWallet(resp.data);
    }
  });

  const generateTransaction = async () => {
    const payload = {
      amount: `${amount}.00`,
      toAddress: walletAddress,
      coinToken: "MG",
    };

    if (amount > dataManaWallet.balance || amount === 0) {
      AlertUser.insufficientBalance();
    } else {
      const data = await postData("/v1/wallet/to-external", payload);
      if (data) {
        AlertUser.succesfulTransaction();
        setshowWithdraw(false);
      }
    }
  };

  useEffect(() => {
    dataWallet();
  }, []);

  return (
    /* <div className="row">
      {(window.localStorage.getItem("addressAccountUser") !== "") &
      (window.localStorage.getItem("addressAccountUser") !== null) ? (
        <>
          <p className="ColorWhiteAddressBalance">
            Wallet Address: {wallet.address}
          </p>
        </>
      ) : null}

      <div className="btnMargin">
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            wallet.address === null ? setUserAccount() : disconnectMetamask()
          }
        >
          {wallet.address === null ? "connect wallet" : "Disconect wallet"}
        </Button>
      </div>
    </div>
    */

    <div className="Container">
      <div className="row ">
        <div
          className="col-sm-12 col-md-12 col-lg-5 d-flex justify-content-center"
          style={{ flexDirection: "column" }}
        >
          <Card className="card-wallet">
            <Card.Body>
              <div className="card-address-btns">
                <div className="address">
                  <span id="address" className="addressAccount">
                    {dataManaWallet
                      ? convertHash(dataManaWallet.address)
                      : null}
                  </span>
                  <FaCopy
                    onClick={() =>
                      navigator.clipboard.writeText(dataManaWallet.address)
                    }
                    className="icon-copy"
                  />
                  {/* <p className="icon-copy">P</p> */}
                </div>

                <div className="containerCurrentBalance">
                  <div className="currenceBalanceC">
                    <p className="currenceBalance">MNG Balance</p>
                    <div className="flow">
                      {/* <p className="dolarBalance">${dataManaWallet ? dataManaWallet.balance : null}</p> */}
                      <p className="flowBalance">
                      MNG = {dataManaWallet ? dataManaWallet.balance : null}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Row className="section-btns-card mt-4">
                <Col onClick={() => functionDeposit()}>
                  <div className="btn">
                    <IoMdAddCircle className="icon-btn" />
                    <div className="deposit"> Deposit</div>
                  </div>
                </Col>
                <Col >{/*onClick={() => functionWithdraw()} */}
                  <div className="btn">
                    <AiFillMinusCircle className="icon-btn" />
                    <div className="history">Coming Soon</div>
                  </div>
                </Col>
              </Row>

              {showWithdraw === true ? (
                <>
                  {" "}
                  <Row className="addresss">
                    <Col>
                      <input
                        type="text"
                        placeholder="Type Your Blockchain Address"
                        className="blockchainAddress"
                        onChange={(e) => {
                          setwalletAddress(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="addresss">
                    <Col>
                      <input
                        type="number"
                        placeholder="Amount"
                        className="blockchainAddress"
                        onChange={(e) => {
                          setamount(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>{" "}
                </>
              ) : null}

              {showDeposit === true ? (
                <>
                  <div className="container-P">
                    <p style={{ paddingTop: "1rem" }}>
                      just have to send mana token to:
                      <p
                        className="hashInternalWallet"
                        onClick={() =>
                          navigator.clipboard.writeText(dataManaWallet.address)
                        }
                      >
                        {" "}
                        {dataManaWallet.address}
                      </p>
                    </p>
                  </div>

                  {/* <Row className="addresss">
                    <Col>
                      <input
                        type="text"
                        placeholder="Type Your Blockchain Address"
                        className="blockchainAddress"
                        onChange={(e) => {
                          setdepositWalletAddress(e.target.value);
                        }}
                      />
                    </Col>
                  </Row> */}
                  {/* <Row className="addresss">
                    <Col>
                      <input
                        type="number"
                        placeholder="Amount"
                        className="blockchainAddress"
                        onChange={(e) => {
                          setamountDeposit(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>{" "} */}
                </>
              ) : null}
            </Card.Body>
          </Card>
          {showWithdraw === true ? (
            <button
              className="send"
              disabled={
                (amount !== undefined) &
                  (walletAddress !== "") &
                  (walletAddress !== undefined)
                  ? false
                  : true
              }
              onClick={() => generateTransaction()}
            >
              Send
            </button>
          ) : null}
          {/* {showDeposit === true ? (
            <button
              className="send"
              disabled={
                (amountDeposit !== undefined) &
                (depositWalletAddress !== "") &
                (depositWalletAddress !== undefined)
                  ? false
                  : true
              }
              onClick={() => deposit()}
            >
              Deposit
            </button>
          ) : null} */}
        </div>
        <div className="col-sm-12 col-md-12 col-lg-7">
          <PanelWallet />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return { wallet: state.wallet };
};
export default connect(mapStateToProps)(Wallet);
