import React, { useEffect, useState } from "react";
import "../assets/styles/GamesChallange.css";
import NavBar from "./NavBar";
import SideBar from "./SideBar";
import { connect, useDispatch } from "react-redux";
import { getChallenges, searchChallenges, setLoading } from "../actions";
import { Link } from "react-router-dom";
import HeaderSection from "./HeaderSection";
import Search from "../assets/Search";
import Price from "../assets/Price";
import Bnb from "../assets/Bnb";
import ccd from "../assets/ccd.png";
import AlertUser from "../Alerts/userAlerts";
import { BsSearch } from "react-icons/bs";

const GamesChallange = ({ navbarSize, challenges }) => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();

  //search challenge by name.
  const ChallengesByNames = async () => {
    if (search.length > 2) {
      dispatch(setLoading(true));
      dispatch(searchChallenges(search));
    } else {
      AlertUser.insertMoreCharacters();
    }
  };

  //function key up enter
  function handleKeyPress(event) {
    if (event.key === "Enter") {
      if (search.length > 2) {
        ChallengesByNames();
      } else {
        AlertUser.insertMoreCharacters();
      }
    }
  }

  //get all challenges
  const Challenges = async () => {
    dispatch(setLoading(true));
    dispatch(getChallenges(1, 50));
  };
  useEffect(() => {
    Challenges();
  }, []);

  return (
    <>
      <NavBar />
      <SideBar />
      <div className={navbarSize === "small" ? "custom-container2" : "custom-container"}>
        <div className="container-fluid">
          <div className="row" key={"RowKey"}>
            <div className="col-md-12">
              <HeaderSection title="Your Challenge" />
            </div>
            <div className=" container-section-search">
              <div className=" containerInputSearch">
                <div className="container-icon-input">
                  <input
                    type="text"
                    name="inputSearchNFT"
                    onKeyPress={handleKeyPress}
                    className="SearchInput"
                    placeholder=" Search Challenge"
                    value={search}
                    onChange={({ target }) => {
                      setSearch(target.value);
                      if (target.value.length === 0) {
                        Challenges();
                      }
                    }}
                  ></input>
                  <span onClick={ChallengesByNames}>
                    <div className="iconSearch">
                      <BsSearch />
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row containerChallenges">
          {challenges.length > 0 ? (
            challenges
              .filter(function (item) {
                return item.status !== "expired";
              })
              .map(function (item, index) {
                return (
                  <>
                    <div className="col-md-6 col-lg-3 mt-4 " key={index}>
                      <div className="card-challenge">
                        <Link to={`/challenge/${item._id}`}>
                          <div
                            className="bg-pict"
                            style={{
                              backgroundImage: `url(${item.urlImage
                                ? item.urlImage
                                : item.game.urlImage
                                })`,
                            }}
                          >
                            <span className="status">{item.status}</span>
                            <div className="price">
                              {item.coinToken === "CDC" ? (
                                <img src={ccd} alt="concordium" style={{ width: 20 }} />
                              ) : item.coinToken === "BNB" || item.coinToken === "MM" ? (
                                <Bnb />
                              ) : (
                                <Price />
                              )}
                              <span style={{ marginLeft: 5, marginTop: 2 }}>
                                {item.amount}
                              </span>
                            </div>
                          </div>
                        </Link>
                        <div className="content">
                          <h4>{item.game.gameName}</h4>
                          <p>{item.name}</p>
                          <Link
                            to={`/challenge/${item._id}`}
                            className="btn--primary btn-full btn-link colorwhite"
                          >
                            View Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })
          ) : (
            <div className="noChallenges">No Challenges To Show</div>
          )}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    navbarSize: state.alerts.navbarSize,
    challenges: state.data.challenges,
  };
};
export default connect(mapStateToProps)(GamesChallange);
