import React from "react";
import ReactDOM from "react-dom";
import ManaWalletProvider from "./custom-providers/useWallet"
import {
  WithWalletConnector,
  useWalletConnectorSelector,
} from "@concordium/react-components";
import {
  TESTNET,
  WALLET_CONNECT_OPTIONS_TESTNET
} from "./setting-concordium";
import "./index.scss";
import App from "./App";
import amplify from "aws-amplify";
import config from "./aws-exports";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers";
import thunk from "redux-thunk";
import { Web3Provider } from "@ethersproject/providers";
import { Web3ReactProvider, initializeConnector, } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { BscConnector } from "@binance-chain/bsc-connector";

function getLibrary(provider) {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

amplify.configure(config);
const store = createStore(reducers, applyMiddleware(thunk));

ReactDOM.render(
  <ManaWalletProvider>
    <Web3ReactProvider connectors={[
      initializeConnector(() => new BscConnector({
        supportedChainIds: [56, 97]
      })),
      initializeConnector((actions) => new MetaMask(actions)),
    ]} >
      <WithWalletConnector
        network={TESTNET}
        walletConnectOpts={WALLET_CONNECT_OPTIONS_TESTNET}
      >
        {(props) => <Provider store={store}>
          <App concordiumProps={props} />
        </Provider>
        }
      </WithWalletConnector>
    </Web3ReactProvider>
  </ManaWalletProvider>,
  document.getElementById("root")
);