/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
// import { viewdetailTXT } from "../../utils/networks/networkFlow";

// import { convertHash } from "./utils/convertHash";
import Table from "react-bootstrap/Table";
import moment from "moment";
import { getData } from "../../apis";
import { convertHash } from "./convertHash";

export const TableTXT = () => {
  const [transactions, settransactions] = useState();

  const getTransactions = async () => {
    const resp = await getData("/v1/user/get/manaCoinHistory?page=1&size=50");
    if (resp) {
      settransactions(resp.data.results);
    }
  };

  useEffect(() => {
    getTransactions();
  }, []);

  return (
    <div className="table-responsive ">
      <div className="sizeTable">
        <Table className="table-wallet " bordered>
          <thead className="pb-4">
            <tr>
              <th
                style={{ width: "30px" }}
                id="start-item-txt"
                className="text-center title-txt font-weight-bold"
              >
                #
              </th>
              <th
                style={{ width: "100px" }}
                className="text-center title-txt font-weight-bold"
              >
                Date
              </th>
              <th
                style={{ width: "150px" }}
                className="text-center title-txt font-weight-bold"
              >
                Hash
              </th>
              <th
                style={{ width: "100px" }}
                className="text-center title-txt font-weight-bold"
              >
                Interactions
              </th>
              <th
                id="finally-item-txt"
                style={{ width: "100px" }}
                className="text-center title-txt font-weight-bold"
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody className="mt-4 tableBody">
            {transactions ? (
              transactions.length > 0 ? (
                transactions.map((item, index) => (
                  <tr key={index} className="tableRow ">
                    <td className="text-center  m-4 paddingTable">
                      {index + 1}
                    </td>
                    <td className="text-center  m-4 paddingTable">
                      {moment(item.createdAt).format("DD MM YYYY ")}
                    </td>

                    {item.blockchainDetails ? (
                      <td className="text-center  m-4 paddingTable">
                        <p
                          id="view-txt"
                          className=""
                          target="_blank"
                          rel="noreferrer"
                          onClick={() =>
                            navigator.clipboard.writeText(
                              item.blockchainDetails.hash
                            )
                          }
                        >
                          {convertHash(item.blockchainDetails.hash)}
                        </p>
                      </td>
                    ) : (
                      <td className="text-center  m-4 paddingTable">
                        <p
                          id="view-txt"
                          className=""
                          target="_blank"
                          rel="noreferrer"
                          style={{ fontSize: "1.2rem" }}
                        >
                          -
                        </p>
                      </td>
                    )}

                    <td className="text-center  m-4 paddingTable">
                      {item.usedIn}
                    </td>
                    {item.blockchainDetails ? (
                      <td className="text-center  m-4 paddingTable">
                        <span
                          className={
                            item.blockchainDetails.confirmations !== 0
                              ? "badge-success-td"
                              : "badge-error-td"
                          }
                        >
                          {item.blockchainDetails.confirmations !== 0
                            ? "success"
                            : "pending"}
                        </span>
                      </td>
                    ) : item.challenge !== null ? (
                      <td className="text-center  m-4 paddingTable">
                        <span className={"badge-success-td"}>success</span>
                      </td>
                    ) : (
                      <div className="default-">-</div>
                    )}
                  </tr>
                ))
              ) : (
                <div className="withoutTransactions">No transactions found</div>
              )
            ) : null}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
