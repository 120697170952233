import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import useWindowSize from "../custom-Hooks/screen-width";
import "./../assets/styles/Forgot-Password.css";
import AlertUser from "../Alerts/userAlerts";
import Header1 from "./Header1";

const ResetVerification = () => {
  let history = useHistory();
  const [username, setUsername] = useState("");
  const size = useWindowSize();
  const [code, setCode] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [confirmNewPassword, setconfirmNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const changePassword = async () => {
    if ((code !== "") & (newPassword !== "") & (confirmNewPassword !== "")) {
      if (newPassword === confirmNewPassword) {
        try {
          const resp = await Auth.forgotPasswordSubmit(
            username,
            code,
            newPassword
          );
          if (resp === "SUCCESS") {
            history.push("/signIn");
          }
        } catch (error) {
          AlertUser.invalidCode(error.message);
        }
      } else {
        AlertUser.differentpasswords();
      }
    } else {
      AlertUser.allFieldsRequired();
    }
  };

  useEffect(() => {
    setUsername(window.localStorage.getItem("email-verify"));
  }, []);

  return (
    <div className="Auth-pages-container Auth">
      <Header1 />
      <div className="container">
        <div className="row d-flex">
          <div className="col-md-6 dark-bg">
            <div className="title-signup">Reset Password ?</div>
            <div className="form-content">
              <div className="fields">
                <input
                  className="form-field"
                  type="text"
                  placeholder="Insert Your Email Code"
                  value={code}
                  onChange={({ target }) => setCode(target.value)}
                />
                <i className="fas fa-envelope" />
              </div>
              <div className="fields">
                <input
                  className="form-field"
                  type={showPassword ? "text" : "password"}
                  placeholder="New Password"
                  value={newPassword}
                  onChange={({ target }) => setnewPassword(target.value)}
                />
                <i
                  className="fas fa-eye"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
              <div className="fields">
                <input
                  className="form-field"
                  type={showPassword ? "text" : "password"}
                  placeholder="Confirm new Password"
                  value={confirmNewPassword}
                  onChange={({ target }) => setconfirmNewPassword(target.value)}
                />
                <i
                  className="fas fa-eye"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
              <button className="btn--primary" onClick={changePassword}>
                Submit
              </button>
              <div className="terms">
                <Link to="/Forgot-Password" className="terms-label">
                  Don’t Received? Resend
                </Link>
              </div>
            </div>
          </div>
          {size.width > 768 ? (
            <div className="col-md-6 bg-signin d-flex align-items-center justify-content-center">
              <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                <div className="title">Hey There!</div>
                <hr className="line" />
                <div className="description">
                  Welcome Back.
                  <br /> You are just one step away to your <br />
                  feed.
                </div>
                <div style={{ textAlign: "center" }}>
                  <button
                    className="btn-transparent"
                    onClick={() => history.push("signup")}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ResetVerification;
