import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import useWindowSize from "../custom-Hooks/screen-width";
import "./../assets/styles/Forgot-Password.css";
import AlertUser from "../Alerts/userAlerts/index";
import Header1 from "./Header1";

const ForgotPassword = () => {
  let history = useHistory();

  const [username, setUsername] = useState("");

  const size = useWindowSize();

  const sendMail = async () => {
    try {
      if (username !== "") {
        const sendMail = await Auth.forgotPassword(username);
        if (sendMail) {
          history.push("/resetverification");
          window.localStorage.setItem("email-verify", username);
        }
      } else {
        AlertUser.insertEmail();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="Auth-pages-container Auth">
      <Header1 />
      <div className="container">
        <div className="row d-flex">
          <div className="col-md-6 dark-bg">
            <div className="title-signup">Forgot Password ?</div>
            <div className="form-content">
              <div className="fields">
                <input
                  className="form-field"
                  type="text"
                  placeholder="Email"
                  value={username}
                  onChange={({ target }) => setUsername(target.value)}
                />
                <i className="fas fa-user" />
              </div>
              <button className="btn--primary" onClick={sendMail}>
                Submit
              </button>
            </div>
          </div>
          {size.width > 767 ? (
            <div className="col-md-6 bg-signin d-flex align-items-center justify-content-center">
              <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                <div className="title">Hey There!</div>
                <hr className="line" />
                <div className="description">
                  Welcome Back.
                  <br /> You are just one step away to your <br />
                  feed.
                </div>
                <div style={{ textAlign: "center" }}>
                  <button
                    className="btn-transparent"
                    onClick={() => history.push("signup")}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
