import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const PublicRoute = (props) => {
  const isSignedIn = props.isSignedIn;
  if (isSignedIn) {
    return <Redirect to="/Games" />;
  }

  return <Route {...props} />;
};
const mapStateToProps = (state) => {
  return { isSignedIn: state.auth.isSignedIn };
};
export default connect(mapStateToProps)(PublicRoute);
