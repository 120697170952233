import AWS from "aws-sdk";

AWS.config.update({
  region: "us-east-1",
  accessKeyId: "AKIAWUPJ77DPLLHKNDS4",
  secretAccessKey: "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",
});
const s3 = new AWS.S3({
  region: "us-east-1",
});

/**
 * @param file image you want to send to s3
 */

const sendImage = async (file, callback, directory = "challenges") => {
  await s3
    .upload({
      Bucket: "bucket-mana",
      Key: directory + "/" + new Date().getTime() + "-" + file.name,
      Body: file,
      ACL: "public-read",
    })
    .promise()
    .then((data) => callback(data))
    .catch((err) => console.error("error S3:", err));
};

const S3 = {
  sendImage,
};

export default S3;
