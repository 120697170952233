import React, { createContext, useContext, useState } from 'react';

/**this function fixes the visual error of the cointoken symbol not to touch*/
function displayCoinToken(coinToken) {
  switch (coinToken) {
    case "MG":
      return "MNG";
    case "CDC":
      return "CCD";
    case "MM":
      return "BNB"
    default: return coinToken;
  }
}

const baseWallet = {
  coinToken: 'MG',
  setCoinToken: () => { },
  walletAddress: '',
  setWalletAddress: () => { },
  walletBalance: '',
  setWalletBalance: () => { },
  isWalletConnected: false,
  setIsWalletConnected: () => { },
  CDCWalletProps: {},
  setCDCWalletProps: () => { },
  walletConnectionActive: null,
  setWalletConnectionActive: () => { },
  binanceContract: null,
  setBinanceContract: () => { },
  hasCDC: true,
  setHasCDC: () => { },
  displayCoinToken: displayCoinToken,
}
const WalletContext = createContext(baseWallet);

const ManaWalletProvider = ({ children }) => {
  const [coinToken, setCoinToken] = useState(baseWallet.coinToken);
  const [walletAddress, setWalletAddress] = useState(baseWallet.walletAddress);
  const [walletBalance, setWalletBalance] = useState(baseWallet.walletBalance);
  const [isWalletConnected, setIsWalletConnected] = useState(baseWallet.isWalletConnected);
  const [walletConnectionActive, setWalletConnectionActive] = useState(baseWallet.walletConnectionActive);
  const [CDCWalletProps, setCDCWalletProps] = useState(baseWallet.CDCWalletProps);
  const [binanceContract, setBinanceContract] = useState(baseWallet.binanceContract);
  const [hasCDC, setHasCDC] = useState(baseWallet.hasCDC);

  return (
    <WalletContext.Provider value={{
      coinToken, walletAddress, walletBalance, isWalletConnected, CDCWalletProps, walletConnectionActive, binanceContract, hasCDC,
      setCoinToken, setWalletAddress, setWalletBalance, setIsWalletConnected, setCDCWalletProps, setWalletConnectionActive, setBinanceContract, displayCoinToken, setHasCDC,
    }}>
      {children}
    </WalletContext.Provider>
  );
};

export default ManaWalletProvider;

export const useManaWallet = () => useContext(WalletContext);