import axios from "axios";
import { signOut } from "../actions";

const networkOfTheSite = window.location.host;

export const URL = networkOfTheSite === 'app.managames.io' ? "https://api.managames.io" : "https://manadevapi.test123.dev";
// export const URL = 'http://localhost:3002';
const instance = axios.create({ baseURL: URL });
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (config.url !== "auth/refreshToken") {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);
instance.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const origininalConfig = error.config;
    if (error.response) {
      if (error.response.status === 401 && !origininalConfig._retry) {
        origininalConfig._retry = true;
        try {
          const token = await refreshToken();
          instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          return instance(origininalConfig);
        } catch (error) {
          if (error.response && error.response.data) {
            return Promise.reject(error.response.data);
          }
        }
      }
    }
    return Promise.reject(error);
  }
);
const refreshToken = async () => {
  const token = await localStorage.getItem("refreshToken");
  const result = await instance.post("/v1/user/auth/refreshToken", {
    refreshToken: token,
  });
  localStorage.setItem("refreshToken", result.data.refresh.token);
  localStorage.setItem("token", result.data.access.token);
  return result.data.access.token;
};
export const getData = async (endpoint) => {
  try {
    let respond = await instance.get(endpoint);
    return { success: true, data: respond.data };
  } catch (error) {
    if (
      error.message === "Request failed with status code 401" ||
      error.message === '"refreshToken" must be a string'
    ) {
      signOut();
      localStorage.removeItem(
        "CognitoIdentityServiceProvider.5buk48cl80dmc3u4itqk0gm2t.LastAuthUser"
      );
    } else {
      return { success: false, data: error.response.data };
    }
  }
};
export const postData = async (endpoint, payload) => {
  try {
    let respond = await instance.post(endpoint, payload);
    return { success: true, data: respond.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
export const patchData = async (endpoint, payload) => {
  try {
    payload = JSON.parse(
      JSON.stringify(
        payload,
        (key, value) => (typeof value === "bigint" ? value.toString() : value) // return everything else unchanged
      )
    );
    let respond = await instance.patch(endpoint, payload);
    return { success: true, data: respond.data };
  } catch (error) {
    return {
      success: false,
      data: error.response ? error.response.data : error.message,
    };
  }
};
export const putData = async (endpoint, payload) => {
  try {
    let respond = await instance.put(endpoint, payload);
    return { success: true, data: respond.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
export const pathData = async (endpoint, payload) => {
  try {
    let respond = await instance.patch(endpoint, payload);
    return { success: true, data: respond.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
export const deleteData = async (endpoint) => {
  try {
    let respond = await instance.delete(endpoint);
    return { success: true, data: respond.data };
  } catch (error) {
    return { success: false, data: error.response.data };
  }
};
