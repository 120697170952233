import { getData, pathData, postData, patchData } from "../apis";

const allCategoryChallenge = async () => {
  try{
    const { data } = await getData("/V1/challenge/all/categories");
    return data;
  } catch (error) {
    throw error;
  }
};

const ChallengesById = async (id, withRefreshBlockchain = false) => {
    if (withRefreshBlockchain === true) {
      return getData(`/v1/challenge/get/by/${id}?withRefreshBlockchain=true`);
    }
    return getData(`/v1/challenge/get/by/${id}`);
  };

const ChallengesByName = async (search) => {
  return getData(`/v1/challenge/search?name=${search}`);
};

const AcceptChallenge = async (id, addressWallet) => {
    if (addressWallet) {
      return pathData(`/v1/challenge/accept/${id}?addressWallet=${addressWallet}`);
    }
    return pathData(`/v1/challenge/accept/${id}`);
  };

const RejectChallenge = async (id) => {
  return pathData(`/v1/challenge/reject/${id}`);
};

const addResult = async (id, winner, myScore) => {  
  return pathData(`/v1/challenge/report-result/${id}`, { winner, myScore });
};

const cancel = async (id) => {
  return pathData(`/v1/challenge/cancel/${id}`);
};

const getChallenges = async () => {
  try {
    const { data } = await getData("/v1/challenge/get");
    return data;
  } catch (error) {
    throw error;
  }
};

const PostChallenge = async (ChallengeData, image, addressWalletCreator = "") => {
  const payload = {
    isPublic: ChallengeData.type === "public" ? true : false,
    challenged: ChallengeData.challenged,
    name: ChallengeData.name,
    startDate: ChallengeData.startDate,
    game: ChallengeData.game,
    description: ChallengeData.description,
    urlImage: image,
    amount: ChallengeData.amount,
    platform: "nothing",
    maxPlayersInPublicChallenge: ChallengeData.players,
    addressWalletCreator: addressWalletCreator,
    coinToken: ChallengeData.coinToken,
  };
  return postData("/v1/challenge", payload);
};

const updateStatusBlockchainChallenge = async (statusString, statusBlockchain, challengeId) => {
    const payload = {
      status: statusString,
      blockchainStatus: statusBlockchain,
    };
    return patchData(`/v1/challenge/status-blockchain/${challengeId}`, payload);
  };

const challengeService = {
  allCategoryChallenge,
  ChallengesById,
  ChallengesByName,
  AcceptChallenge,
  RejectChallenge,
  addResult,
  cancel,
  getChallenges,
  PostChallenge,
  updateStatusBlockchainChallenge
};

export default challengeService;

