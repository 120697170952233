import React, { useEffect } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import SignUp from "../components/signUp";
import SignIn from "../components/signIn";
import ConfirmSignUp from "../components/ConfirmSignUp";
import HomePage from "../pages/HomePage";
import PrivateRoute from "../routes/PrivateRoute";
import PublicRoute from "../routes/PublicRoute";
import GamesChallange from "../components/GamesChallange";
import PostChallengePage from "../pages/PostChallengePage";
import ChatPage from "../pages/chatPage";
// import HistoryOfSale from "../pages/HistoryOfSalePage";
import RegisterGamerProfilePage from "../pages/RegisterGamerProfilePage";
import TermsPrivacy from "../services/Term&Conditions.service";
import ForgotPassword from "../components/Forgot-Password";
import ResetVerification from "../components/Reset-Verification";
import CompleteProfile from "../components/CompleteProfile";
import CompleteProfilePage2 from "../components/CompleteProfilePage2";
import ChangePasswordPage from "../pages/ChangePasswordPage";
import WalletPage from "../pages/WalletPage";
import FriendingPage from "../pages/FriendingPage";
import NftPage_New_release from "../pages/NftPage_New_Release";
import VerifiCodeToChangePasswordPage from "../pages/VerifiCodeToChangePasswordPage";
import { Auth } from "aws-amplify";
import { Hub } from "@aws-amplify/core";
import { getUserInfo, setBalance, setError, signIn, signOut } from "../actions";
import { connect, useDispatch } from "react-redux";
import SingleChallenge from "../pages/SingleChallenge";
import ContactUs from "../pages/ContactUs";
import SocketIOClient from "socket.io-client";
import { URL } from "../apis";
import Alerts from "../components/Alerts";

const AppRouter = ({ loading, isSignedIn, alerts }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    checkLogin();
    loginListen();
  }, []);
  const checkLogin = () => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        dispatch(getUserInfo(res.username));
        const token = localStorage.getItem("token");
        const socket = SocketIOClient(URL, {
          path: "/socket.io",
          transports: ["websocket"],
          auth: {
            token: token,
          },
          forceNew: true,
          reconnectionAttempts: 50000,
          timeout: 2000,
        });
        socket.on("connect", () => {
          console.log("socket connected"); // x8WIv7-mJelg7on_ALbx
        });
        socket.on("balance", (res) => {
          console.log(res);
          dispatch(setBalance(res.balance));
        });
        socket.on("notification", (res) => {
          console.log("res", res);
        });
        socket.on("error", (error) => {
          console.log(error);
        });
        socket.on("reconnect", (attempt) => {
          console.log(attempt);
        });
        socket.on("disconnect", () => {
          console.log(socket.id); // undefined
        });
      })
      .catch(() => {
        dispatch(signOut());
      });
  };
  const loginListen = () => {
    Hub.listen("auth", async (payload) => {
      switch (payload.payload.event) {
        case "signIn":
          dispatch(signIn(payload.payload.data.username));
          break;
        case "signIn_failure":
          dispatch(setError(payload.payload.data.message));
          dispatch(signOut());
          break;
        case "signOut":
          dispatch(signOut());
          break;
        default:
          break;
      }
    });
  };
  return isSignedIn !== null ? (
    <BrowserRouter>
      <Switch>
        <PublicRoute exact path="/" component={SignUp} />
        <PublicRoute exact path="/signup" component={SignUp} />
        <PublicRoute exact path="/signIn" component={SignIn} />
        <PublicRoute exact path="/confirm" component={ConfirmSignUp} />
        <PublicRoute exact path="/Terms-Privacy" component={TermsPrivacy} />
        <PublicRoute exact path="/Forgot-Password" component={ForgotPassword} />
        <PublicRoute
          exact
          path="/resetverification"
          component={ResetVerification}
        />
        <Route path="/CompleteProfile">
          {isSignedIn ? <CompleteProfile /> : <Redirect to="/signin" />}
        </Route>
        <Route
          path="/CompleteProfile2"
          component={isSignedIn ? CompleteProfilePage2 : SignIn}
        />
        <PrivateRoute exact path="/Games" component={HomePage} />
        <PrivateRoute exact path="/GamesChallange" component={GamesChallange} />
        <PrivateRoute exact path="/challenge/:id" component={SingleChallenge} />
        <PrivateRoute
          exact
          path="/postchallenge"
          component={PostChallengePage}
        />
        <PrivateRoute exact path="/chatpage" component={ChatPage} />
        {/* <PrivateRoute exact path="/historyofsale" component={HistoryOfSale} /> */}
        <PrivateRoute
          exact
          path="/registergamerprofile"
          component={RegisterGamerProfilePage}
        />
        <PrivateRoute
          exact
          path="/ChangePassword"
          component={ChangePasswordPage}
        />
        <PrivateRoute exact path="/Wallet" component={WalletPage} />
        <PrivateRoute exact path="/Friending" component={FriendingPage} />
        <PrivateRoute
          exact
          path="/NewRelease"
          component={NftPage_New_release}
        />
        <PrivateRoute
          exact
          path="/VerifiCode"
          component={VerifiCodeToChangePasswordPage}
        />
        <PrivateRoute exact path="/contact-us" component={ContactUs} />
      </Switch>
      {loading.show ? (
        <div className="loading">
          <i className="fas fa-spinner fa-spin" />
          {loading.desc !== "" ? <div>{loading.desc}</div> : null}
        </div>
      ) : null}
      {alerts.error !== null || alerts.success !== null ? (
        <Alerts
          desc={alerts.error !== null ? alerts.error : alerts.success}
          type={alerts.error !== null ? "error" : "success"}
        />
      ) : null}
    </BrowserRouter>
  ) : null;
};
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    loading: state.alerts.loading,
    alerts: state.alerts,
  };
};
export default connect(mapStateToProps)(AppRouter);
