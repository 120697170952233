import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../assets/styles/Auth-css.css";
import { Auth } from "aws-amplify";
import Header1 from "./Header1";
import { connect, useDispatch } from "react-redux";
import { setError, setLoading } from "../actions";
import AlertUser from "../Alerts/userAlerts";

const ConfirmSignUp = () => {
  const [username, setUsername] = useState("");
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const history = useHistory();

  useEffect(() => {
    setUsername(history.location.state["email"]);
  }, []);

  const confirmSignUp = async () => {
    dispatch(setLoading(true));
    try {
      const res = await Auth.confirmSignUp(username, code);
      if (res) {
        // check if password still in params
        if (history.location.state["password"]) {
          await Auth.signIn(username, history.location.state["password"]);
        } else {
          history.push("/signin");
        }
      }
    } catch (error) {
      console.log(error.message);
      if (error.message === "Invalid verification code provided, please try again.") {
        AlertUser.incorrectCode();
        dispatch(setLoading(false));
      } else {
        dispatch(setError(error.message));
      }
    }
  };

  return (
    <div className="Auth-pages-container Auth">
      <Header1 />
      <div className="container">
        <div className="row d-flex">
          <div className="col-md-6 dark-bg">
            <div className="title-signup">Email Code Verification</div>
            <div className="subtitle">
              We have sent you an email with a verification code: 
            </div>
            <div className="form-content">
              {history.location.state["email"] === "" ? (
                <div className="fields">
                  <input
                    className="form-field"
                    type="text"
                    placeholder="Email"
                    value={username}
                    onChange={({ target }) => setUsername(target.value)}
                  />
                  <i className="fas fa-envelope" />
                </div>
              ) : null}
              <div className="fields">
                <input
                  className="form-field"
                  type="text"
                  placeholder="Enter Verification Code"
                  value={code}
                  onChange={({ target }) => setCode(target.value)}
                />
                <i className="fas fa-key" />
              </div>
              <button className="btn--primary" onClick={confirmSignUp}>
                Confirm
              </button>
            </div>
          </div>
          <div className="col-md-6 bg-signup d-flex align-items-center justify-content-center">
            <div>
              <div className="title">Hey There!</div>
              <hr className="line" />
              <div className="description">
                Welcome Back.
                <br /> You are just one step away to your <br />
                feed.
              </div>
              <div style={{ textAlign: "center" }}>
                <button
                  style={{ marginBottom: 20 }}
                  className="btn-transparent"
                  onClick={() => history.push("signin")}
                >
                  Sign In
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default connect(null)(ConfirmSignUp);
