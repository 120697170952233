import React from "react";
import NavBar from "../components/NavBar";
import Wallet_Settings from "../components/Settings-Items-Wallet";
import SideBar from "../components/SideBar";

export default function WalletPage() {
  return (
    <>
      {/*------- NAV-BA------- */}
      <NavBar />
      {/*------- NAV-BA------- */}

      {/*------- left-bar ------- */}
      <SideBar />
      {/*------- left-bar ------- */}
      {/*------- Page-Content ------- */}
      <Wallet_Settings />
      {/*------- Page-Content ------- */}
    </>
  );
}
