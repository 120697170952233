import React from "react";
import { NavLink } from "react-router-dom";
import {
  postchallenge,
  registergamerprofile,
  chatpage,
  Wallett,
  friending,
} from "../routes/routes";

const Subnavigation = () => {
  return (
    <div className="row">
      <div className="col-md-12    ST-custom-vertical-options">
        <NavLink
          activeClassName="activeClassSettings"
          to={postchallenge}
          className="ST-custom-vertical-options-btn"
        >
          Create Challenge
        </NavLink>
        <NavLink
          activeClassName="activeClassSettings"
          to={registergamerprofile}
          className="ST-custom-vertical-options-btn"
        >
          | Profile
        </NavLink>

        <NavLink
          activeClassName="activeClassSettings"
          to={friending}
          className="ST-custom-vertical-options-btn"
        >
          | Friends
        </NavLink>

        <NavLink
          activeClassName="activeClassSettings"
          to={chatpage}
          className="ST-custom-vertical-options-btn"
        >
          | Chat
        </NavLink>

        <NavLink
          activeClassName="activeClassSettings"
          to={Wallett}
          className="ST-custom-vertical-options-btn"
        >
          | Wallet
        </NavLink>

        {/* <NavLink
          activeClassName="activeClassSettings"
          to={historyofsale}
          className="ST-custom-vertical-options-btn"
        >
          History of sale
        </NavLink> */}
      </div>
    </div>
  );
};

export default Subnavigation;
