import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import "../assets/styles/Auth-css.css";
import useWindowSize from "../custom-Hooks/screen-width";
import { Auth } from "aws-amplify";
import axios from "axios";
import Header1 from "./Header1";
import { connect, useDispatch } from "react-redux";
import { setLoading, setError } from "../actions";
import { postData } from "../apis";
import AlertUser from "../Alerts/userAlerts";
import userService from "../services/users"

const SignUp = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const size = useWindowSize();
  const [terms, setTerms] = useState(false);
  const [ip, setIP] = useState("");
  const [answer, setAnswer] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [username, setUsername] = useState("");

  const getData = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      if (res) {
        setIP(res.data.IPv4);
      }
    } catch (error) {
      console.error("error", error.message);
    }
  };

  async function validateFields(e) {
    e.preventDefault();
    if (
      (email !== "") &
      (password !== "") &
      (terms === true) &
      (username !== "")
    ) {
      if (username.length > 3) {
        const userExists = await userService.searchUserByNickName(username);
        if (userExists.data.length > 0 && userExists.data[0].userName === username) {
           dispatch(setError("Username already exists"));
        } else {
          signUp();
        } 
      } else {
        AlertUser.userNameLengthPolicy()
      }
    } else if (email === "" || password === "" || username === "") {
      dispatch(setError("Fields required!"));
    } else if (terms === false) {
      AlertUser.termsAndConditions();
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      validateFields();
    }
  };

  async function signUp() {
    dispatch(setLoading(true));
    try {            
      if (answer === "") {
        const res = await Auth.signUp({
          username: email,
          password,
          attributes: {
            email,
          },
        });
        setAnswer(res);
        if (res) {
          const payload = {};
          payload.idCognito = res.userSub;
          payload.email = email;
          payload.userName = username;
          payload.noHaveEmailAndPhone = true;
          if (ip !== "") {
            payload.iP = ip;
          }
          const SendApi = await postData("/v1/user/auth", payload);
          if (!SendApi.success || SendApi.data.message !== "user saved") {                       
            dispatch(setError(SendApi.data.message));
            
          } else {
            dispatch(setLoading(false));
            history.push("/confirm", { email, password });
          }
        }
        dispatch(setLoading(false));
      } else {
        const SendApi = await postData("/v1/user/auth", {
          idCognito: answer.userSub,
          email,
          userName: username,
          iP: ip,
          noHaveEmailAndPhone: true,
        });
        if (!SendApi.success || SendApi.data.message !== "user saved") {
          dispatch(setError(SendApi.data.message));
        } else {
          dispatch(setLoading(false));
          history.push("/confirm", { email, password });
        }
      }
    } catch (error) {
      switch (error.message) {
        case `1 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$`:
          AlertUser.passwordPolicy();
          dispatch(setLoading(false));          
          break;
        case "Password did not conform with policy: Password not long enough":
          AlertUser.passwordLengthPolicy();
          dispatch(setLoading(false));          
          break;
        default:
          AlertUser.personalError(error.message)       
          dispatch(setLoading(false));
          break;
      }
    }
  }
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="Auth-pages-container Auth">
      <Header1 />
      <div className="container">
        <div className="row d-flex">
          <div className="col-md-6 dark-bg">
            <div className="title-signup">Sign Up</div>
            <div className="subtitle">
              Already have an account? Login to Mana Games
            </div>
            <div className="form-content">
              <form ref={formRef} onSubmit={validateFields} onKeyDown={handleKeyPress}>
                <div className="fields">
                  <input
                    className="form-field"
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={({ target }) => setUsername(target.value)}
                  />
                  <i className="fas fa-user" />
                </div>
                <div className="fields">
                  <input
                    className="form-field"
                    type="text"
                    placeholder="Email"
                    value={email}
                    onChange={({ target }) => setEmail(target.value)}
                  />
                  <i className="fas fa-envelope" />
                </div>
                <div className="fields">
                  <input
                    className="form-field"
                    type={showPass ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={({ target }) => setPassword(target.value)}
                  />
                  <i
                    className="fas fa-eye"
                    onClick={() => setShowPass(!showPass)}
                  />
                </div>
                {/* <div className="fields">
                  <input
                    className="form-field"
                    type="phone"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={({ target }) => setPhone(target.value)}
                  />
                </div> */}
                <div className="terms">
                  <input
                    type="checkbox"
                    id="terms"
                    value={terms}
                    onChange={({ target }) => setTerms(target.checked)}
                  />
                  <label htmlFor="terms" className="terms-label termsHover"  onClick={() => history.push("/Terms-Privacy")}>
                    Allow Term & Conditions
                  </label>
                </div>
                <div className="responsiveLoginBtns">
                  <button className="btn--primary" onClick={validateFields}>
                    Sign Up
                  </button>{" "}
                  {size.width <= 767 ? (
                    <button
                      style={{ marginLeft: "5px" }}
                      className="btn-transparent"
                      onClick={() => history.push("signin")}
                    >
                      Sign In
                    </button>
                  ) : null}
                </div>

                {/* <div className="socials">
                  <div>
                    <p>Or Sign Up with</p>
                  </div>
                  <div className="icons">
                    <Facebook />
                    <div className="space" />
                    <Google />
                    <div className="space" />
                    <Apple />
                    <div className="space" />
                    <Amazon />
                  </div>
                </div> */}
              </form>
            </div>
          </div>
          {size.width > 767 ? (
            <div className="col-md-6 bg-signup d-flex align-items-center justify-content-center">
              <div>
                <div className="title">Hey There!</div>
                <hr className="line" />
                <div className="description">
                  Welcome Back.
                  <br /> You are just one step away to your <br />
                  feed.
                </div>
                <div style={{ textAlign: "center" }}>
                  <button
                    className="btn-transparent"
                    onClick={() => history.push("signin")}
                  >
                    Sign In
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default connect(null)(SignUp);
