export const homeGame = "/Games";
export const Tournament = "/Tournament";
export const AllTournament = "/All-Tournament";
export const Challange = "/GamesChallange";
export const NFT = "/NFT";
export const postchallenge = "/postchallenge";
export const registergamerprofile = "/registergamerprofile";
export const chatpage = "/chatpage";
export const historyofsale = "/historyofsale";
export const detailChallenge = "/Challange";
export const changePassword = "/ChangePassword";
export const Games = "/Swaps";
export const Wallett = "/Wallet";
export const friending = "/Friending";
export const NewRelease = "/NewRelease";
export const PaymentMethodss = "/PaymentMethods";
export const CryptoForm = "/CryptoForm";
export const ContactUs = "/contact-us";
