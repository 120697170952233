import "../assets/styles/Setting-items.css";
import moment from "moment-timezone";
import challengeService from "../services/challenge.service";
import ActiveFriends from "./ActiveFriends";
import S3 from "../services/Aws/s3";
import DragAndDrop from "./DragAndDrop";
import friendService from "../services/friend.service";
import SelectInput from "./SelectInput";
import React, { useState, useEffect, useMemo } from "react";
import { connect, useDispatch } from "react-redux";
import { setError, setLoading, setSuccess } from "../actions";
import { Editor } from "@tinymce/tinymce-react";
import { useHistory } from "react-router-dom";
import { getData } from "../apis";
import { createChallenge } from "../services/concordium.service";
import { useManaWallet } from '../custom-providers/useWallet';
import EditorComponent from "./texteditor";

const PostChallange = () => {
  const { coinToken, walletAddress, walletBalance, walletConnectionActive, isWalletConnected, binanceContract, displayCoinToken } = useManaWallet();
  const [bscWalletConnect, setBscWalletConnect] = useState(false);
  const [imageDragAndDrop, setImageDragAndDrop] = useState([]);
  const [friends, setFriends] = useState([]);
  const [maxPlayers, setMaxPlayers] = useState(-1);
  const min = useMemo(() => {
    const minDate = moment().tz("America/New_York");
    minDate.add(5, "minutes");
    return minDate;
  }, []);
  const [challengeData, setChallengeData] = useState({
    name: "",
    startDate: new Date(min.format("YYYY-MM-DDTHH:mm")).getTime(),
    StartDatePicker: min.format("YYYY-MM-DDTHH:mm"),
    description: "",
    urlImage: "",
    amount: "",
    challenged: [],
    type: "public",
    players: 2,
    requiredWallet: coinToken !== "MG",
    coinToken: coinToken,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const [userUnSelect, setUserUnSelect] = useState("");
  const [items, setItems] = useState([]);
  const [empty, setEmpty] = useState(null);

  function clearImgDragAndDrop() {
    if (empty === null) {
      setEmpty([]);
    }
  }

  const clearForm = () => {
    setChallengeData({
      name: "",
      startDate: new Date(min.format("YYYY-MM-DDTHH:mm")).getTime(),
      game: "",
      description: "",
      urlImage: "",
      amount: "",
      challenged: [],
      type: "public",
      players: 2,
      requiredWallet: coinToken !== "MG",
      coinToken: coinToken,
    });
    clearImgDragAndDrop();
  };

  const games = async () => {
    const respFriends = await friendService.getAllFriend(1, 50);
    if (respFriends.success) {
      setFriends(respFriends.data.results);
    }
  };

  const sendPictures3 = async () => {
    dispatch(
      setLoading(
        true,
        <p>
          We are loading your challenge, <br />
          do not close this window or your challenge will fail
        </p>
      )
    );
    // if image url exist that mean that the user already tried to upload the challenge but something went wrong so this time dont upload the image and push the challenge
    if (challengeData.urlImage !== "") {
      postDataChallenge(challengeData.urlImage);
    } else {
      try {
        if (
          (imageDragAndDrop.length !== 0) &
          (imageDragAndDrop !== undefined)
        ) {
          await S3.sendImage(imageDragAndDrop, function (data) {
            if (data) {
              postDataChallenge(data.Location);
            }
          });
        } else {
          const Game = items.filter(
            (imagen) => imagen._id === challengeData.game
          );
          postDataChallenge(Game[0].urlImage);
        }
      } catch (error) {
        console.log("error con s3", error);
        dispatch(setLoading(false));
      }
    }
  };

  const postDataChallenge = async (url) => {
    const res = await challengeService.PostChallenge(
      challengeData,
      url,
      walletAddress
    );
    if (res.success) {
      // we have to create transaction on blockchain concordium
      if (res.data.data.coinToken === "CDC") {
        try {
          await createChallenge(
            walletAddress,
            walletConnectionActive.client,
            res.data.data
          );
          dispatch(setLoading(false));
        } catch (error) {
          console.error("error", error);
          await challengeService.cancel(res.data.data._id);
          dispatch(setLoading(false));
          return dispatch(setError(error.message));
        }
      } else if (res.data.data.coinToken === "BNB" || res.data.data.coinToken === "MM") {
        try {
          const dataBNB = res.data.data;
          const maxUsers = dataBNB.isPublic === true ? dataBNB.maxPlayersInPublicChallenge : dataBNB.challenged.length;
          const responseBNB = await binanceContract.createChallenge(dataBNB._id, dataBNB.name, dataBNB.game.gameName, String(dataBNB.amount), new Date(dataBNB.startDate), [], maxUsers)
          await challengeService.updateStatusBlockchainChallenge("finalized", responseBNB, dataBNB._id);
          dispatch(setLoading(false));
        } catch (err) {
          await challengeService.cancel(res.data.data._id);
          if (err.message.includes("execution reverted: Maximum acceptors must be greater than to zero.")) {
            dispatch(setLoading(false));
            return dispatch(setError("Maximum acceptors must be greater than to zero."));
          } else {
            dispatch(setLoading(false));
            return dispatch(setError(err.message));
          }
        }
      }
      clearForm();
      clearImgDragAndDrop();
      dispatch(setSuccess("Challenge Created successfully!"));
      history.push("/GamesChallange");
    } else {
      dispatch(
        setError(
          res.data.message === "you don't have gamer tag for this challenge"
            ? "To play a challenge you must register a gamer ID for this game"
            : res.data.message
        )
      );
      let users = [];
      challengeData.challenged.forEach((e) => {
        users.push(friends.filter((res) => res._id === e)[0]);
      });
      users.forEach((e) => {
        if (res.data.message.search(e.userName) > 0) {
          setUserUnSelect(e._id);
        }
      });
    }
  };
  const checkRequired = async () => {
    // we check if balance of wallet is enough to create challenge
    if (challengeData.requiredWallet) {
      const balance = Number(walletBalance);
      if (balance < challengeData.amount) {
        dispatch(
          setError(
            `You don't have enough balance in your wallet, you need at least ${challengeData.amount} ${coinToken}`
          )
        );
        return false;
      }
    }
    let gameImage = items.filter((it) => it._id === challengeData.game);
    if (challengeData.name === "") {
      dispatch(setError("Name is required!"));
      return false;
    }
    if (challengeData.description === "") {
      dispatch(setError("Description is required!"));
      return false;
    }
    const selectedStartDate = new Date(challengeData.startDate);
    const currentDateTime = moment().tz("America/New_York");
    const minimumStartDate = currentDateTime.clone().add(5, "minutes");
    if (selectedStartDate <= minimumStartDate) {
      dispatch(setError("Start date must be at least 5 minutes in the future."));
      return false;
    }
    if (challengeData.type === "public" && challengeData.players < 2) {
      dispatch(setError("Minimum of 2 players required"));
      return false;
    }
    if (challengeData.game === undefined || challengeData.game === "") {
      dispatch(setError("Game is required!"));
      return false;
    }
    if (challengeData.amount === "") {
      dispatch(setError("Amount is required!"));
      return false;
    }
    if (challengeData.startDate === "") {
      dispatch(setError("Date is required!"));
      return false;
    }
    if (challengeData.type === "") {
      dispatch(setError("Please select the challenge type"));
      return false;
    }
    if (
      gameImage.length > 0 &&
      gameImage.urlImage === "" &&
      challengeData.urlImage === "" &&
      imageDragAndDrop.length === 0
    ) {
      dispatch(setError("Please select an image"));
      return false;
    }
    if (challengeData.type === "public") {
      if (challengeData.players === 0) {
        dispatch(setError("Please select the number of players"));
        return false;
      }
    } else {
      if (challengeData.challenged.length === 0 && challengeData.coinToken === "MG") {
        dispatch(setError("Please select your friends"));
        return false;
      }
    }
    sendPictures3();
  };

  const showPostChallengeButton = () => {//Hablar con Osmany sobre borrar esto
    if (challengeData.requiredWallet) {
      if (isWalletConnected === true || bscWalletConnect === true) {
        return true;
      }
      return false;
    }
    return true;
  }

  useEffect(() => {
    games();
    document
      .getElementById("input-date")
      .addEventListener("focus", function (event) {
        event.target.showPicker();
      });
  }, []);

  useEffect(() => {
    const getComments = async () => {
      const res = await getData(`/v1/games/get/all?page=1`);
      if (res) {
        const data1 = res.data.results;
        const res2 = await getData(`/v1/games/get/all?page=2`);
        if (res2) {
          const data2 = res2.data.results;
          const filterGames = [...data1, ...data2];
          const leakedGames = filterGames.filter(function (Games) {
            return Games.toCreateChallenges === true;
          });
          const sortedItems = [...leakedGames].sort((a, b) => a.order - b.order);
          setItems(sortedItems);
        }
      }
    };

    getComments();
  }, []);

  useEffect(() => {
    setChallengeData({
      ...challengeData,
      coinToken: coinToken,
      requiredWallet: coinToken !== "MG",
      type: coinToken === "CDC" ? "public" : challengeData.type,
    })
  }, [coinToken])

  useEffect(() => {
    if (challengeData.type === "private") {
      setChallengeData({
        ...challengeData,
        players: 0,
        challenged: [],
      })
    }
  }, [challengeData.type])

  return (
    <>
      <div className="row">
        <div className="col-md-5">
          <div className="containerlabel">
            <div className="ST-inputs-title">Challenge Name</div>
          </div>
          <div className="ST-inputs-container">
            <input
              type="text"
              placeholder="One Tap Mike"
              value={challengeData.name}
              onChange={({ target }) =>
                setChallengeData({
                  ...challengeData,
                  name: target.value,
                })
              }
              className="ST-input-l"
            />
          </div>
          <SelectInput
            options={[
              { value: "private", label: "Private" },
              { value: "public", label: "Public" },
            ]}
            label="Challenge type"
            name="type"
            placeholder="Is it public or private?"
            optionLabel="label"
            optionValue="value"
            onChange={(val) => {
              setChallengeData({
                ...challengeData,
                type: val,
              });
            }}
          />
          {challengeData.type === "public" ? (
            <>
              <div className="containerlabel">
                <div className="ST-inputs-title">Number of players</div>
              </div>
              <div className="ST-inputs-container">
                <input
                  style={{ width: "100%" }}
                  type="number"
                  placeholder="2"
                  className="ST-input-l-bt"
                  value={challengeData.players}
                  min="2"
                  onChange={({ target }) =>
                    setChallengeData({
                      ...challengeData,
                      players: target.value,
                    })
                  }
                />
              </div>
            </>
          ) : null}
          <div className="containerlabel">
            <div className="ST-inputs-title" style={{ marginBottom: "10px" }}>
              Challenge Description
            </div>
          </div>
          <div style={{marginBottom: 8}}>
            {/*className="ST-inputs-container2" */}

            <EditorComponent onValueChange={(newValue) => {
              setChallengeData({
                ...challengeData,
                description: newValue,
              })
            }}
            />
          </div>

          <SelectInput
            options={items}
            label="Select Game"
            name="games"
            placeholder="Select Game"
            optionLabel="gameName"
            optionValue="_id"
            onChange={(val) => {
              // set max number of players
              let selected = items.filter((res) => res._id === val);
              // add -1 because we need to include the current user
              try {
                setMaxPlayers(selected[0].maxChallenged - 1);
              } catch (error) {
                console.error(error)
              }
              setChallengeData({
                ...challengeData,
                game: val,
              });
            }}
          />
          <div className="containerlabel">
            <div className="ST-inputs-title">Challenge Price</div>
          </div>
          <div className="ST-inputs-container">
            <input
              style={{ width: "100%" }}
              type="number"
              placeholder={`0 ${displayCoinToken(coinToken)}`}
              className="ST-input-l-bt"
              value={challengeData.amount}
              onChange={({ target }) =>
                setChallengeData({
                  ...challengeData,
                  amount: target.value,
                })
              }
            />
          </div>
          <div style={{ marginBottom: 20 }}>
            <div className="containerlabel">
              <div className="ST-inputs-title">
                Start Date{" "}
                <small style={{ color: "#767676" }}>
                  (All times are saving in EST){" "}
                  {moment().tz("America/New_York").format("MM/DD/YYYY HH:mm")}
                </small>
              </div>
            </div>
            <div className=" ST-inputs-container-bt">
              <input
                type="datetime-local"
                id="input-date"
                className="form-control"
                value={challengeData.StartDatePicker}
                onChange={({ target }) => {
                  setChallengeData({
                    ...challengeData,
                    startDate: new Date(target.value).getTime(),
                    StartDatePicker: target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="subtitle">Add a cover to your challenge</div>
          <div className=" ST-center-image-container">
            <DragAndDrop
              clear={empty}
              onSetImage={(f) => {
                setImageDragAndDrop(f[0]);
              }}
            />
          </div>
          {isWalletConnected ? (
            <div>
              <h6
                className="text-white"
                style={{
                  textAlign: "center",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                You Are Using {displayCoinToken(coinToken)}
              </h6>
            </div>
          ) : (
            <div>
              <h6
                className="text-white"
                style={{
                  textAlign: "center",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                You Need to connect a Wallet to Create A Challenge
              </h6>
            </div>
          )}
          <div className="ST-center-btn-container containerPostChallenge">
            <button className="ST-c-c-bt" onClick={clearForm}>
              Cancel
            </button>
            <button
              style={{
                display:
                  showPostChallengeButton()
                    ? "block"
                    : "none",
              }}
              className="ST-c-l-bt"
              onClick={() => checkRequired()}
            >
              Post Challenge
            </button>
          </div>
        </div>
        {challengeData.type === "private" ? (
          <div className="col-md-3">
            <div className="H-items-container-post-challange">
              <div className="subtitle"> My Friends</div>
              {maxPlayers > 0 ? (
                <p style={{ color: "white", fontSize: 12 }}>
                  you can select a max of {maxPlayers} friends for this game
                </p>
              ) : null}
              <div className="">
                <ActiveFriends
                  max={maxPlayers}
                  user={friends}
                  unSelect={userUnSelect}
                  onSelect={(id, action) => {
                    if (action === "add") {
                      setChallengeData({
                        ...challengeData,
                        challenged: [...challengeData.challenged, id],
                        players: coinToken === "BNB" ? challengeData.players + 1 : challengeData.players,
                      });
                    } else {
                      let index = challengeData.challenged.indexOf(id);
                      if (index !== -1) {
                        challengeData.challenged.splice(index, 1);
                        setChallengeData({
                          ...challengeData,
                          challenged: challengeData.challenged,
                          players: coinToken === "BNB" ? challengeData.players - 1 : challengeData.players,
                        });
                        if (userUnSelect === id) {
                          setUserUnSelect("");
                        }
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    navbarSize: state.alerts.navbarSize,
    Games: state.data.games,
  };
};
export default connect(mapStateToProps)(PostChallange);