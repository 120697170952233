const networkOfTheSite = window.location.host;

const wallet = {
    address: null,
    balance: null,
    chainId: null,
}

const formatBalance = (rawBalance) => {//etherjs ya tiene forma de cambiar el balance
    const balance = (parseInt(rawBalance) / 1000000000000000000).toFixed(2)
    return balance
}
const formatChainAsNum = (chainIdHex) => {
    const chainIdNum = parseInt(chainIdHex)
    return chainIdNum
}

const getWalletData = async (metamaskProvider) => {
    const accounts = await metamaskProvider.request({
        method: "eth_requestAccounts",
    })
    wallet.address = accounts[0]
    wallet.chainId = formatChainAsNum(await metamaskProvider.request({ method: 'eth_chainId' }));
    wallet.balance = formatBalance(await metamaskProvider.request({
        method: "eth_getBalance",
        params: [wallet.address, "latest"],
    }));
    return wallet;
};

const changeChainId = async (metamaskProvider) => {
    if (networkOfTheSite === "app.managames.io") {
        try {
            await metamaskProvider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x38' }],
            });
        } catch (switchError) {
            await metamaskProvider.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainId: "0x38", //56
                    chainName: 'Binance Smart Chain',
                    nativeCurrency: {
                        name: 'Binance Coin',
                        symbol: 'BNB',
                        decimals: 18
                    },
                    rpcUrls: ['https://bsc-dataseed.binance.org/'],
                    blockExplorerUrls: ['https://bscscan.com']
                }],
            });
        }
    } else {
        try {
            await metamaskProvider.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x61' }],
            });
        } catch (switchError) {
            await metamaskProvider.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainId: "0x61", //97
                    chainName: 'BSC Testnet',
                    nativeCurrency: {
                        name: 'Binance Coin',
                        symbol: 'BNB',
                        decimals: 18
                    },
                    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
                    blockExplorerUrls: ['https://testnet.bscscan.com']
                }],
            });
        }
    }
};

const MetamaskService = {
    getWalletData,
    changeChainId
};
export default MetamaskService;
