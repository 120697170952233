import React from "react";
import { TableTXT } from "./TableTXT";

export const PanelWallet = () => {
  return (
    <div className="heightTable">
      <div className="tab-wallet">Transactions</div>

      <div>
        <TableTXT />
      </div>
    </div>
  );
};
